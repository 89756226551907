import React from 'react';

function Nav(props) {
  // 단순 공백 만드는 컴포넌트
  return (
    <>
      <div style={{ margin: '20px', padding: '15px', backgroundColor: 'rgba(0, 0, 0, 0)' }}>
      
      </div>
    </>
  );
}

export default Nav;