import React from 'react';
import { Link } from 'react-router-dom';
import '../header.css';

function Header(props) {
    return (
        <div className="header">
            <div className="header-background"></div>
            <div className="header-text">
                <h1>
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>개발자 유미</Link>
                </h1>
            </div>
        </div>
    );
}

export default Header;