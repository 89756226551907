import hljs from 'highlight.js';
import 'highlight.js/styles/vs2015.css';

import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
   DecoupledEditor,
   AccessibilityHelp,
   Alignment,
   Autoformat,
   AutoImage,
   AutoLink,
   Autosave,
   BalloonToolbar,
   BlockQuote,
   Bold,
   Code,
   CodeBlock,
   Essentials,
   FindAndReplace,
   FontBackgroundColor,
   FontColor,
   FontFamily,
   FontSize,
   GeneralHtmlSupport,
   Heading,
   Highlight,
   HorizontalLine,
   HtmlComment,
   HtmlEmbed,
   ImageBlock,
   ImageCaption,
   ImageInline,
   ImageInsert,
   ImageInsertViaUrl,
   ImageResize,
   ImageStyle,
   ImageTextAlternative,
   ImageToolbar,
   ImageUpload,
   Indent,
   IndentBlock,
   Italic,
   Link,
   LinkImage,
   List,
   ListProperties,
   MediaEmbed,
   Paragraph,
   RemoveFormat,
   SelectAll,
   SpecialCharacters,
   SpecialCharactersArrows,
   SpecialCharactersCurrency,
   SpecialCharactersEssentials,
   SpecialCharactersLatin,
   SpecialCharactersMathematical,
   SpecialCharactersText,
   Strikethrough,
   Style,
   Subscript,
   Superscript,
   Table,
   TableCaption,
   TableCellProperties,
   TableColumnResize,
   TableProperties,
   TableToolbar,
   TextTransformation,
   TodoList,
   Underline,
   Undo
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

import '../editor.css';

export default function Editor(props) {
   const editorContainerRef = useRef(null);
   const editorToolbarRef = useRef(null);
   const editorRef = useRef(null);
   const [isLayoutReady, setIsLayoutReady] = useState(false);
   const [toolbarGrouping, setToolbarGrouping] = useState(true);

   useEffect(() => {
      setIsLayoutReady(true);
      
      // 아이패드보다 작은 기기에서는 툴바 그루핑 (... 버튼)
      if (matchMedia("screen and (max-width: 761px").matches) {
         setToolbarGrouping(false);
      }
      return () => setIsLayoutReady(false);
   }, []);

   function uploadAdapter(loader) {
      return {
         upload: () => {
            return new Promise((resolve, reject) => {
               loader.file.then(file => {
                  const reader = new FileReader();
                  reader.onload = () => {
                     const imageData = reader.result.split(',')[1]; // base64 데이터 추출

                     fetch('https://www.devyummi.com/api/v1/image', {
                        method: 'POST',
                        headers: {
                           'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                           image: imageData,
                           authorization: 'rlawlgns654'
                        })
                     })
                        .then((res) => res.json())
                        .then(res => {
                           resolve({
                              default: res.url // Lambda 함수에서 반환된 이미지 URL을 사용합니다.
                           });
                        })
                        .catch(error => {
                           console.error('Error uploading image:', error);
                           reject(error.message || 'Failed to upload image');
                        });
                  };
                  reader.readAsDataURL(file);
               })
                  .catch(error => {
                     console.error('Error getting file from loader:', error);
                     reject(error);
                  });
            });
         }
      };
   }

   function uploadPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
         return uploadAdapter(loader);
      };
   }

   const editorConfig = {
      toolbar: {
         items: [
            'undo',
            'redo',
            '|',
            'findAndReplace',
            'selectAll',
            '|',
            'heading',
            'style',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'code',
            'removeFormat',
            '|',
            'specialCharacters',
            'horizontalLine',
            'link',
            'insertImage',
            'mediaEmbed',
            'insertTable',
            'highlight',
            'blockQuote',
            'codeBlock',
            '|',
            'alignment',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            'indent',
            'outdent',
            '|',
            'accessibilityHelp'
         ],
         shouldNotGroupWhenFull: toolbarGrouping
      },
      plugins: [
         AccessibilityHelp,
         Alignment,
         Autoformat,
         AutoImage,
         AutoLink,
         Autosave,
         BalloonToolbar,
         BlockQuote,
         Bold,
         Code,
         CodeBlock,
         Essentials,
         FindAndReplace,
         FontBackgroundColor,
         FontColor,
         FontFamily,
         FontSize,
         GeneralHtmlSupport,
         Heading,
         Highlight,
         HorizontalLine,
         HtmlComment,
         HtmlEmbed,
         ImageBlock,
         ImageCaption,
         ImageInline,
         ImageInsert,
         ImageInsertViaUrl,
         ImageResize,
         ImageStyle,
         ImageTextAlternative,
         ImageToolbar,
         ImageUpload,
         Indent,
         IndentBlock,
         Italic,
         Link,
         LinkImage,
         List,
         ListProperties,
         MediaEmbed,
         Paragraph,
         RemoveFormat,
         SelectAll,
         SpecialCharacters,
         SpecialCharactersArrows,
         SpecialCharactersCurrency,
         SpecialCharactersEssentials,
         SpecialCharactersLatin,
         SpecialCharactersMathematical,
         SpecialCharactersText,
         Strikethrough,
         Style,
         Subscript,
         Superscript,
         Table,
         TableCaption,
         TableCellProperties,
         TableColumnResize,
         TableProperties,
         TableToolbar,
         TextTransformation,
         TodoList,
         Underline,
         Undo,
         uploadPlugin
      ],
      mediaEmbed: { // 유튜브 영상 첨부 보이게
         previewsInData: true
      },
      balloonToolbar: ['bold', 'italic', '|', 'link', 'insertImage', '|', 'bulletedList', 'numberedList'],
      fontFamily: {
         supportAllValues: true
      },
      fontSize: {
         options: [10, 12, 14, 'default', 18, 20, 22],
         supportAllValues: true
      },
      heading: {
         options: [
            {
               model: 'paragraph',
               title: 'Paragraph',
               class: 'ck-heading_paragraph'
            },
            {
               model: 'heading1',
               view: 'h1',
               title: 'Heading 1',
               class: 'ck-heading_heading1'
            },
            {
               model: 'heading2',
               view: 'h2',
               title: 'Heading 2',
               class: 'ck-heading_heading2'
            },
            {
               model: 'heading3',
               view: 'h3',
               title: 'Heading 3',
               class: 'ck-heading_heading3'
            },
            {
               model: 'heading4',
               view: 'h4',
               title: 'Heading 4',
               class: 'ck-heading_heading4'
            },
            {
               model: 'heading5',
               view: 'h5',
               title: 'Heading 5',
               class: 'ck-heading_heading5'
            },
            {
               model: 'heading6',
               view: 'h6',
               title: 'Heading 6',
               class: 'ck-heading_heading6'
            }
         ]
      },
      htmlSupport: {
         allow: [
            {
               name: /^.*$/,
               styles: true,
               attributes: true,
               classes: true
            }
         ]
      },
      image: {
         toolbar: [
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
            '|',
            'resizeImage'
         ]
      },
      link: {
         // addTargetToExternalLinks: true, // 새 창으로 열림
         defaultProtocol: 'https://',
         decorators: {
            toggleDownloadable: {
               mode: 'manual',
               label: 'Downloadable',
               attributes: {
                  download: 'file'
               }
            }
         }
      },
      list: {
         properties: {
            styles: true,
            startIndex: true,
            reversed: true
         }
      },
      placeholder: '안녕하세요!',
      style: {
         definitions: [
            {
               name: 'Article category',
               element: 'h3',
               classes: ['category']
            },
            {
               name: 'Title',
               element: 'h2',
               classes: ['document-title']
            },
            {
               name: 'Subtitle',
               element: 'h3',
               classes: ['document-subtitle']
            },
            {
               name: 'Info box',
               element: 'p',
               classes: ['info-box']
            },
            {
               name: 'Side quote',
               element: 'blockquote',
               classes: ['side-quote']
            },
            {
               name: 'Marker',
               element: 'span',
               classes: ['marker']
            },
            {
               name: 'Spoiler',
               element: 'span',
               classes: ['spoiler']
            },
            {
               name: 'Code (dark)',
               element: 'pre',
               classes: ['fancy-code', 'fancy-code-dark']
            },
            {
               name: 'Code (bright)',
               element: 'pre',
               classes: ['fancy-code', 'fancy-code-bright']
            }
         ]
      },
      table: {
         contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
      }
   };

   // editor data
   const { editorData, setEditorData } = props;
   // code block highlighting
   useEffect(() => {
      const codeBlocks = document.querySelectorAll('pre code');
      codeBlocks.forEach(block => {
         if (!block.hasAttribute("data-highlighted")) {
            hljs.highlightElement(block);
         }
      });
   }, [editorData]);

   return (
      <div className="main-container">
         <div className="editor-container editor-container_document-editor" ref={editorContainerRef}>
            <div className="editor-container__toolbar" ref={editorToolbarRef}></div>
            <div className="editor-container__editor-wrapper">
               <div className="editor-container__editor">
                  <div ref={editorRef}>
                     {isLayoutReady && (
                        <CKEditor
                           data={editorData}
                           onChange={(event, editor) => {
                              setEditorData(editor.getData());
                           }}
                           onReady={editor => {
                              editorToolbarRef.current.appendChild(editor.ui.view.toolbar.element);
                           }}
                           onAfterDestroy={() => {
                              Array.from(editorToolbarRef.current.children).forEach(child => child.remove());
                           }}
                           editor={DecoupledEditor}
                           config={editorConfig}
                        />
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
