import { useEffect, useState } from "react";
import Editor from "../components/Editor";
import '../create.css';
import fetchPage from "../utils/fetchPage";
import { useNavigate, useSearchParams } from "react-router-dom";
import useTitle from '../utils/useTitle';

export default function Create() {
    const [params, setParams] = useSearchParams('');
    const id = params.get('id');
    const setHtmlTitle = useTitle('새 글 작성');

    const [data, setData] = useState('');

    const [title, setTitle] = useState('');
    const [editorData, setEditorData] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [icon, setIcon] = useState('');

    const navigate = useNavigate();

    // 접근 확인
    useEffect(() => {

        const password = prompt('enter :');
        if (password !== 'rlawlgns654') {
          alert('비밀번호가 틀렸습니다.');
          navigate('/');
        }

    }, [navigate]);

    useEffect(() => {
        if (id) {
            fetchPage(id, setData);
            setHtmlTitle('글 수정')
        }
    }, [id]);

    useEffect(() => {
        setTitle(data.title || '');
        setEditorData(data.content || '');
        setUsername(data.username || '');
        setIcon(data.icon || '');
    }, [data]);

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        const url = 'https://www.devyummi.com/api/v1/page' + (id ? `?id=${id}` : "");
        const response = await fetch(url, {
            method: id ? "PUT" : "POST",
            body: JSON.stringify({
                title: title,
                content: editorData,
                username: username,
                password: password,
                icon: icon,
            })
        });
        if (response.ok) {
            const result = await response.json();
            navigate(`/page?id=${ id ? id : result.id}`);
        }
    }

    return (
        <div className="container">
            {/* 정렬 위해 grid 사용. container 자식으로 3개 item */}
            <div className="item"></div>
            <div className="item">
                <form className="form" onSubmit={onSubmitHandler}>
                    <div className="form-section1">
                        <div className="item">
                            <label htmlFor="username">username</label>
                        </div>
                        <div className="item">
                            <input type="text" id="username" value={username} placeholder="username"
                                onChange={(e) => { setUsername(e.target.value) }} />
                        </div>
                        <div className="item">
                            <label htmlFor="password">password</label>
                        </div>
                        <div className="item">
                            <input type="password" id="password" value={password} placeholder="password"
                                onChange={(e) => { setPassword(e.target.value) }} />
                        </div>
                        <div className="item">
                            <label htmlFor="title">아이콘 주소</label>
                        </div>
                        <div className="item">
                            <input type="text" id="icon" value={icon} placeholder="icon"
                                onChange={(e) => { setIcon(e.target.value) }}></input>
                        </div>
                    </div>
                    <div className="form-section2">
                        <div className="item">
                            <label htmlFor="title">제목</label>
                        </div>
                        <div className="item">
                            <input type="text" id="title" value={title} placeholder="title"
                                onChange={(e) => { setTitle(e.target.value) }}></input>
                        </div>
                    </div>
                    <Editor editorData={editorData} setEditorData={setEditorData}></Editor>
                    <button type="submit" id="submit">글쓰기</button>
                </form>
            </div>
            <div className="item"></div>
        </div>
    );
}