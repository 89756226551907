import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import 'highlight.js/styles/vs2015.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../list.css';

// API URLs
const BASE_URL = 'https://www.devyummi.com/api/v1';

const fetchPosts = async (sort, count, offset) => {
    try {
        const response = await fetch(`${BASE_URL}/list?count=${count}&offset=${offset}`);
        const data = await response.json();
        return Array.isArray(data.data) ? data.data : [];
    } catch (error) {
        console.error("Error fetching posts:", error);
        return [];
    }
};

const fetchTotalCount = async () => {
    try {
        const response = await fetch(`${BASE_URL}/count?collection=PageCollection`);
        const data = await response.json();
        return data.count || 0;
    } catch (error) {
        console.error("Error fetching total count:", error);
        return 0;
    }
};

const List = () => {
    const [params, setParams] = useSearchParams();
    const [posts, setPosts] = useState([]);
    const [totalPosts, setTotalPosts] = useState(0);
    const [loading, setLoading] = useState(true);
    const currentPage = parseInt(params.get('page') || 1, 10);
    const postsPerPage = 10;

    useEffect(() => {
        const loadPosts = async () => {
            setLoading(true); // 데이터 로딩 시작
            const count = await fetchTotalCount();
            setTotalPosts(count);

            const offset = (currentPage - 1) * postsPerPage;
            const data = await fetchPosts('Aesc', postsPerPage, offset);
            setPosts(data);
            setLoading(false); // 데이터 로딩 완료
        };

        loadPosts();
    }, [currentPage]);

    const handlePageChange = (page) => {
        setParams({ page });
    };

    return (
        <div className="posts-list">
            <h1>내용 정리 Docs 리스트</h1>
            <hr></hr>
            {loading ? (
                <p>...</p>
            ) : (
                <>
                    <ul>
                        {posts.length > 0 ? (
                            posts.map(post => (
                                <li key={post._id}>
                                    <a href={`/page?id=${post._id}`}> {post.title} </a>
                                </li>
                            ))
                        ) : (
                            <li>게시글이 없습니다.</li>
                        )}
                    </ul>
                    <Pagination
                        totalPosts={totalPosts}
                        postsPerPage={postsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                </>
            )}
            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                closeOnClick
                rtl={false}
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

const Pagination = ({ totalPosts, postsPerPage, currentPage, onPageChange }) => {
    const totalPages = Math.ceil(totalPosts / postsPerPage);

    // 페이지네이션 버튼에 보여줄 페이지 범위
    const maxButtons = 10; // 최대 페이지 버튼 개수
    const startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2)); // 시작 페이지
    const endPage = Math.min(totalPages, startPage + maxButtons - 1); // 끝 페이지

    const handlePageClick = (page) => {
        if (page < 1 || page > totalPages) return;
        onPageChange(page);
    };

    return (
        <div className="pagination">
            <button onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
            {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
                <button
                    key={startPage + i}
                    onClick={() => handlePageClick(startPage + i)}
                    className={currentPage === startPage + i ? 'active' : ''}
                >
                    {startPage + i}
                </button>
            ))}
            <button onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
        </div>
    );
};

export default List;