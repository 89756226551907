import { toast } from 'react-toastify';

export default function createCopyButton(block) {
    // Clipboard API
    let langaugeName = '';
    block.classList.forEach((el) => {
        if (el.includes('language')) {
            langaugeName = el.split('-')[1].toUpperCase();
        }
    })
    const copyButton = document.createElement('button');

    const icon = document.createElement('i');
    icon.classList.add('fa-regular');
    icon.classList.add('fa-clipboard');

    const langEl = document.createElement('span');
    langEl.textContent = langaugeName;
    copyButton.className = 'copy-button';

    copyButton.appendChild(icon);
    copyButton.appendChild(langEl);

    copyButton.addEventListener('click', () => {
        navigator.clipboard.writeText(block.textContent)
            .then(() => {
                // react-toastify
                toast.success("클립보드에 복사되었습니다.");
            })
            .catch(err => {
                toast.error("클립보드 복사에 실패했습니다.: ", err);
            })
    });

    // pre 태그에 copyButton 추가
    block.parentNode.appendChild(copyButton);
}