import './App.css';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Nav from './components/Nav';
import Count from './components/Count';
import Home from './pages/Home';
import Page from './pages/Page';
import Create from './pages/Create';
import List from './pages/List';

function App() {
  return (
    <div className="App">
        <Header />
        <Nav />
        <Count />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/list" element={<List/>}></Route>
            <Route path="/page" element={<Page/>}></Route>
            <Route path="/create" element={<Create/>}></Route>
            {/* <Route path="/login" element={<Login />} /> */}
          </Routes>
        <Nav />
        <Footer />
    </div>
  );
}

export default App;
