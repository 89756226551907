import '../footer.css';

function Footer(props) {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <h4>관리자</h4>
                    <ul>
                        <li><a href="https://github.com/xxxjjhhh" target='__blank'>김지훈 : @xxxjjhhh</a></li>
                        <li><a href="https://github.com/suhsein" target='__blank'>서세인 : @suhsein</a></li>
                        <li><a>관리자 : xxxjjhhh@gmail.com</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>고객 지원</h4>
                    <ul>
                        <li><a>이용약관</a></li>
                        <li><a>개인정보처리방침</a></li>
                        <li><a>인재모집</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>서비스</h4>
                    <ul>
                        <li><a href="https://www.youtube.com/@xxxjjhhh" target='__blank'>개발자 유미 유튜브</a></li>
                        <li><a href="#"></a></li>
                        <li><a href="#"></a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                &copy; 2024 개발자 유미. 모든 권리 보유.
            </div>
        </footer>
    );
}

export default Footer;