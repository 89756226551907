import { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

import hljs from 'highlight.js';
import 'highlight.js/styles/vs2015.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import fetchPage from "../utils/fetchPage";
import createCopyButton from "../utils/createCopyButton";
import useTitle from '../utils/useTitle';

import BottomAd from '../components/BottomAd';

import '../page.css';

export default function Page() {

    // 페이지 전환 후 상단으로 이동
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 50);
    }, [location]);

    // 게시글 내용 <a href> -> Link
    const navigate = useNavigate();
    const clickEvent = (event) => {
        if (event.target.tagName === 'A') {

            const href = event.target.getAttribute('href');
            const myDomain = 'www.devyummi.com';
            
            // 우리 도메인에 대해서만 Link로 진행, 외부 도메인은 그대로
            if (href.includes(myDomain)) {
                event.preventDefault();
                const url = new URL(href);
                navigate(url.pathname + url.search + url.hash);
            }
        }
    };

    const [params, setParams] = useSearchParams();
    const [data, setData] = useState(null);

    const setHtmlTitle = useTitle();

    const id = params.get('id');

    useEffect(() => {
        fetchPage(id, setData);
    }, [id]);

    useEffect(() => {
        const codeBlocks = document.querySelectorAll('pre code');
        codeBlocks.forEach(block => {
            if (!block.hasAttribute("data-highlighted")) {
                hljs.highlightElement(block);
                createCopyButton(block);
            }
        });
        setHtmlTitle(data ? data.title : "...");
    }, [data]);

    return (
        <>
            {data ? (
                <>
                    <div className="page-body">
                        {data.icon && <img src={data.icon} className="page-icon" alt="icon" />}
                        <h1 className="page-title">{data.title}</h1>
                        <p>최종 변경 : {data.updated}</p>
                        <hr></hr>
                        <p><br></br></p>
                        <div className="page-content" dangerouslySetInnerHTML={{ __html: data.content }} onClick={clickEvent}></div>
                        <p><br></br></p>
                        <BottomAd />
                    </div>
                </>
            ) : (
                <p>...</p>
            )}

            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                closeOnClick
                rtl={false}
                pauseOnHover
                theme="light"
            />
        </>

    );
}

