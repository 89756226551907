const fetchPage = async (id, setData) => {
    const response = await fetch(`https://www.devyummi.com/api/v1/page?id=${id}`, {
        method: 'GET'
    });

    if (response.ok) {
        const result = await response.json();
        setData(result.data);
    } else {
        console.log('failed');
    }
}

export default fetchPage;