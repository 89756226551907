import React from 'react';

function Count(props) {
  // 단순 공백 만드는 컴포넌트
  return (
    <>
      <div style={{ margin: '20px', padding: '15px', backgroundColor: 'rgba(0, 0, 0, 0)' }}>
      <a href="https://hits.seeyoufarm.com"><img src="https://hits.seeyoufarm.com/api/count/incr/badge.svg?url=https%3A%2F%2Fwww.devyummi.com&count_bg=%23E600FF&title_bg=%23555555&icon=&icon_color=%23E7E7E7&title=%EA%B0%9C%EB%B0%9C%EC%9E%90+%EC%9C%A0%EB%AF%B8&edge_flat=false"/></a>
      </div>
    </>
  );
}

export default Count;